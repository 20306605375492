/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Slideは、コアコンポーネントではなく、それぞれのスライドを管理するサブコンポーネントです。いくつかのイベントハンドラから取得できるほか、Slidesコンポーネント経由でアクセスできます。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "index",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#index",
    "aria-label": "index permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "index")), "\n", React.createElement(Type, {
    code: 'index: number'
  }), "\n", React.createElement(_components.p, null, "スライドのインデックスです。このインデックスは、クローンの場合マイナスか、あるいは全体数以上の値になっている可能性があります。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slideindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slideindex",
    "aria-label": "slideindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slideIndex")), "\n", React.createElement(Type, {
    code: 'slideIndex: number'
  }), "\n", React.createElement(_components.p, null, "クローンスライドが対応している実際のスライドインデックスです。クローンではない場合、常に", React.createElement(_components.code, null, "-1"), "が入っています。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slide",
    "aria-label": "slide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slide")), "\n", React.createElement(Type, {
    code: 'slide: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "スライドの要素が入っています。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "container",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#container",
    "aria-label": "container permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "container")), "\n", React.createElement(Type, {
    code: 'container: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "コンテナ要素が入っています。HTMLで与えられなかった場合は", React.createElement(_components.code, null, "undefined"), "です。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "isclone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#isclone",
    "aria-label": "isclone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isClone")), "\n", React.createElement(Type, {
    code: 'isClone: boolean'
  }), "\n", React.createElement(_components.p, null, "クローンかどうかを示すブーリアンです。"), "\n", React.createElement(_components.h2, {
    id: "メソッド-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89-1",
    "aria-label": "メソッド 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#style",
    "aria-label": "style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "style()")), "\n", React.createElement(Type, {
    code: 'style( prop: string, value: string | number, useContainer?: boolean ): void'
  }), "\n", React.createElement(_components.p, null, "CSSをスライド、またはコンテナに対して適用します。"), "\n", React.createElement(Params, {
    params: [['prop', '適用するプロパティ名'], ['value', 'CSSの値'], ['useContainer', '省略可。スタイルをスライドに適用するか、あるいはコンテナに適用するかを決める']]
  }), "\n", React.createElement(_components.h3, {
    id: "iswithin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#iswithin",
    "aria-label": "iswithin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isWithin()")), "\n", React.createElement(Type, {
    code: 'isWithin( from: number, distance: number ): boolean'
  }), "\n", React.createElement(_components.p, null, "スライドが、", React.createElement(_components.code, null, "from"), "で与えられるインデックスからどの程度離れているかを計算し、", React.createElement(_components.code, null, "distance"), "以下の場合に", React.createElement(_components.code, null, "true"), "を返す関数です。"), "\n", React.createElement(Params, {
    params: [['from', '基準となるスライドのインデックス'], ['distance', 'テストするための距離（インデックスの差）']],
    ret: '基準からの距離が`distance`以下であれば`true`、そうでない場合は`false`を返す'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
